import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { dispatch } from 'redux/actions/index';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

const CustomCircularProgress = withStyles({
  root: {
    color: '#000000', // force black spinner color
  },
})(CircularProgress);

const StyledDialog = withStyles({
  paper: {
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    backgroundColor: '#2a2a2a',
    color: '#f0b90b', // Gunthy gold color
    padding: '16px 24px',
  },
})(DialogTitle);

const StyledDialogContent = withStyles({
  root: {
    padding: '24px',
  },
})(DialogContent);

const StyledDialogActions = withStyles({
  root: {
    padding: '16px 24px',
    justifyContent: 'flex-end',
  },
})(DialogActions);

const StyledButton = withStyles({
  root: {
    backgroundColor: '#f0b90b',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#D8A70A',
    },
  },
})(Button);

class CoreDisabled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      sample: _.sample([true, false, false]),
      startLoading: false,
    };
  }

  handleClick(view) {
    const config = this.props.config;
    const isMobile = window.innerWidth <= 600;
    const sendNotification = (text, variant) => {
      this.props.enqueueSnackbar(text, {
        variant: variant || 'success',
        preventDuplicate: true,
        style: { whiteSpace: 'pre-line' },
      });
    };

    if (view === '/startCore') {
      // Start loading indicator
      this.setState({ startLoading: true });
      let telegramError = false;
      let orphanPair = false;
      let orphanStrat = [];
      // fill orphan vars
      Object.keys(config.pairs).forEach(exchange => {
        if (Object.keys(config.exchanges).indexOf(exchange) === -1) {
          orphanPair = true;
        }
        Object.keys(config.pairs[exchange]).forEach(pair => {
          if (Object.keys(config.strategies).indexOf(config.pairs[exchange][pair].strategy) === -1) {
            orphanStrat.push(`${pair} (${exchange})`);
          }
        });
      });
      // check telegramError
      if (
        config.bot.TELEGRAM_ENABLED === true &&
        (config.bot.chat_id === '' || config.bot.admin_id === '' || config.bot.TOKEN === '')
      ) {
        telegramError = true;
      }
      // actions
      if (!_.isNil(config.bot.gunthy_wallet) && config.bot.gunthy_wallet === '') {
        sendNotification('Enter your Gunthy wallet address first', 'info');
        this.setState({ startLoading: false });
      } else if (_.isEmpty(config.exchanges)) {
        sendNotification('Connect an exchange first');
        this.setState({ startLoading: false });
      } else if (orphanPair === true) {
        sendNotification('Connect missing exchange for one or more trading pairs', 'info');
        this.setState({ startLoading: false });
      } else if (orphanStrat.length > 0) {
        sendNotification(`Assign a valid strategy name to: ${orphanStrat.join(', ')}`, 'info');
        this.setState({ startLoading: false });
      } else if (telegramError === true) {
        sendNotification(
          'Telegram token, chat ID and/or admin ID not set. \nDisable the Telegram bot or add the right credentials.',
          'info'
        );
        this.setState({ startLoading: false });
      } else {
        // Dispatch the start action; assume it returns a promise
        Promise.resolve(this.props.dispatch('startBotCore')).finally(() => {
          this.setState({ startLoading: false });
        });
      }
    } else {
      this.props.history.push(view);
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  getDialogContent() {
    const hasPairs =
      Object.keys(this.props.pairs).length > 0 &&
      Object.keys(this.props.pairs?.[Object.keys(this.props.pairs)[0]] || {}).length > 0;
    const hasOrderNumberKey = !_.isNil(this.props.bot?.orderNumber);
    const hasGunthyWallet =
      !_.isNil(this.props.bot?.gunthy_wallet) && this.props.bot?.gunthy_wallet.length > 1;
    const hasExchangeAndOrderNumber = hasOrderNumberKey
      ? Object.keys(this.props.exchanges).length > 0 && this.props.bot?.orderNumber.length > 0
      : false;
    const modeString = _.isNil(this.props.bot?.GB_SIMULATOR)
      ? 'trading'
      : this.props.bot?.GB_SIMULATOR === true
      ? 'simulator'
      : 'trading';

    let data = {
      header: '',
      text: '',
      buttonText: '',
      buttonTarget: ''
    };

    if ((hasPairs && hasExchangeAndOrderNumber && hasOrderNumberKey) || (hasGunthyWallet && hasPairs)) {
      data.header = `Start trading`;
      data.text = `Click 'Start ${modeString}' to activate your trading strategies. Please note that starting or stopping briefly refreshes this page.`;
      data.buttonText = `Start ${modeString}`;
      data.buttonTarget = '/startCore';
    } else if (
      (!hasPairs && hasExchangeAndOrderNumber && hasOrderNumberKey) ||
      (hasGunthyWallet && !hasPairs)
    ) {
      data.header = `Add trading pairs`;
      data.text = `There are no configured trading pairs, add one or more pairs to trade. You can do this on the trading settings page.`;
      data.buttonText = `Open trading settings`;
      data.buttonTarget = '/trading-settings';
    } else if (hasPairs && !hasExchangeAndOrderNumber && hasOrderNumberKey) {
      data.header = `Complete setup first`;
      data.text = `There are no connected exchanges or the license details are not entered yet. Go to the setup page and complete the initial configuration.`;
      data.buttonText = `Open setup`;
      data.buttonTarget = '/setup';
    } else if (!hasPairs && !hasExchangeAndOrderNumber && hasOrderNumberKey) {
      data.header = `Complete setup first`;
      data.text = `There are no connected exchanges and no trading pairs. Go to the setup page and first complete the initial configuration, then add trading pairs.`;
      data.buttonText = `Open setup`;
      data.buttonTarget = '/setup';
    } else {
      data.header = `Verify your settings`;
      data.text = `Make sure you've connected an exchange, have entered your license details and one or more trading pairs are configured.`;
      data.buttonText = `Open setup`;
      data.buttonTarget = '/setup';
    }
    return data;
  }

  render() {
    const dialogContent = this.getDialogContent();
    const isMobile = window.innerWidth <= 600;
    return (
      <StyledDialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          {dialogContent.header}
        </StyledDialogTitle>
        <StyledDialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: '#cccccc' }}>
            {dialogContent.text}
          </DialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          {dialogContent.buttonTarget === '/trading-settings' &&
          this.props.config.GUI?.DASHBOARD_MODE === 'base' ? null : (
            <StyledButton
              onClick={() => this.handleClick(dialogContent.buttonTarget)}
              // On mobile, make the button full width
              style={isMobile ? { width: '100%' } : {}}
              disabled={dialogContent.buttonTarget === '/startCore' && this.state.startLoading}
            >
              {dialogContent.buttonTarget === '/startCore' && this.state.startLoading ? (
                <CustomCircularProgress size={20} />
              ) : (
                dialogContent.buttonText
              )}
            </StyledButton>
          )}
        </StyledDialogActions>
      </StyledDialog>
    );
  }
}

export default withSnackbar(
  withRouter(
    connect(
      state => ({
        config: state.settings.config,
        bot: state.settings.config.bot,
        exchanges: state.settings.config.exchanges,
        pairs: state.settings.config.pairs,
      }),
      { dispatch }
    )(CoreDisabled)
  )
);
